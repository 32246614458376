export interface IEvent {
  type?: string;
  source?: string;
  date?: Date;
  tags?: string[];
  message?: string;
  geo?: string;
  value?: number;
  data?: any;
  reference_id?: string;
  session_id?: string;
}

export interface ILastReferenceIdManager {
  getLast(): string;
  clearLast(): void;
  setLast(eventId: string): void;
}

export interface ILog {
  info(message: string): void;
  warn(message: string): void;
  error(message: string): void;
}

                                          

export interface IEventQueue {
  enqueue(event: IEvent): void;
  process(isAppExiting?: boolean): void;
  suspendProcessing(durationInMinutes?: number, discardFutureQueuedItems?: boolean, clearQueue?: boolean): void;
}

                                                                                                                                  

export interface IEnvironmentInfoCollector {
  getEnvironmentInfo(context: EventPluginContext): IEnvironmentInfo;
}

                                                                                                              

export interface IErrorParser {
  parse(context: EventPluginContext, exception: Error): IError;
}

                                                                                                                

export interface IModuleCollector {
  getModules(context: EventPluginContext): IModule[];
}

                                                                                                                          

export interface IRequestInfoCollector {
  getRequestInfo(context: EventPluginContext): IRequestInfo;
}

                                              

export interface IStorage<T> {
  save(path: string, value: T): boolean;
  get(path: string): T;
  getList(searchPattern?: string, limit?: number): IStorageItem<T>[];
  remove(path: string): void;
}

                                                                                                                   

export interface ISubmissionAdapter {
  sendRequest(request: SubmissionRequest, callback: SubmissionCallback, isAppExiting?: boolean): void;
}

                                                                                                                                                                                                                                                                                           

export interface ISubmissionClient {
  postEvents(events: IEvent[], config: Configuration, callback: (response: SubmissionResponse) => void, isAppExiting?: boolean): void;
  postUserDescription(referenceId: string, description: IUserDescription, config: Configuration, callback: (response: SubmissionResponse) => void): void;
  getSettings(config: Configuration, callback: (response: SettingsResponse) => void): void;
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            

export interface IConfigurationSettings {
  apiKey?: string;
  serverUrl?: string;
  environmentInfoCollector?: IEnvironmentInfoCollector;
  errorParser?: IErrorParser;
  lastReferenceIdManager?: ILastReferenceIdManager;
  log?: ILog;
  moduleCollector?: IModuleCollector;
  requestInfoCollector?: IRequestInfoCollector;
  submissionBatchSize?: number;
  submissionClient?: ISubmissionClient;
  submissionAdapter?: ISubmissionAdapter;
  storage?: IStorage<any>;
  queue?: IEventQueue;
}

                                                                                                                                                     

export class SettingsManager {
  /**
   * The configuration settings path.
   * @type {string}
   * @private
   */
  private static _configPath: string = 'ex-server-settings.json';

  /**
   * A list of handlers that will be fired when the settings change.
   * @type {Array}
   * @private
   */
  private static _handlers: { (config: Configuration): void }[] = [];

  public static onChanged(handler: (config: Configuration) => void) {
    !!handler && this._handlers.push(handler);
  }

  public static applySavedServerSettings(config: Configuration): void {
    config.log.info('Applying saved settings.');
    config.settings = Utils.merge(config.settings, this.getSavedServerSettings(config));
    this.changed(config);
  }

  public static checkVersion(version: number, config: Configuration): void {
    if (version) {
      let savedConfigVersion = parseInt(<string>config.storage.get(`${this._configPath}-version`), 10);
      if (isNaN(savedConfigVersion) || version > savedConfigVersion) {
        config.log.info(`Updating settings from v${(!isNaN(savedConfigVersion) ? savedConfigVersion : 0) } to v${version}`);
        this.updateSettings(config);
      }
    }
  }

  public static updateSettings(config: Configuration): void {
    if (!config.isValid) {
      config.log.error('Unable to update settings: ApiKey is not set.');
      return;
    }

    config.submissionClient.getSettings(config, (response: SettingsResponse) => {
      if (!response || !response.success || !response.settings) {
        return;
      }

      config.settings = Utils.merge(config.settings, response.settings);

      // TODO: Store snapshot of settings after reading from config and attributes and use that to revert to defaults.
      // Remove any existing server settings that are not in the new server settings.
      let savedServerSettings = SettingsManager.getSavedServerSettings(config);
      for (let key in savedServerSettings) {
        if (response.settings[key]) {
          continue;
        }

        delete config.settings[key];
      }

      let path = SettingsManager._configPath; // optimization for minifier.
      config.storage.save(`${path}-version`, response.settingsVersion);
      config.storage.save(path, response.settings);

      config.log.info('Updated settings');
      this.changed(config);
    });
  }

  private static changed(config: Configuration) {
    let handlers = this._handlers; // optimization for minifier.
    for (let index = 0; index < handlers.length; index++) {
      handlers[index](config);
    }
  }

  private static getSavedServerSettings(config: Configuration): Object {
    return config.storage.get(this._configPath) || {};
  }
}

                                                                    

export class DefaultLastReferenceIdManager implements ILastReferenceIdManager {
  /**
   * Gets the last event's reference id that was submitted to the server.
   * @type {string}
   * @private
   */
  private _lastReferenceId: string = null;

  /**
   * Gets the last event's reference id that was submitted to the server.
   * @returns {string}
   */
  getLast(): string {
    return this._lastReferenceId;
  }

  /**
   * Clears the last event's reference id.
   */
  clearLast(): void {
    this._lastReferenceId = null;
  }

  /**
   * Sets the last event's reference id.
   * @param eventId
   */
  setLast(eventId: string): void {
    this._lastReferenceId = eventId;
  }
}

                              

export class ConsoleLog implements ILog {
  public info(message: string): void {
    this.log('info', message);
  }

  public warn(message: string): void {
    this.log('warn', message);
  }

  public error(message: string): void {
    this.log('error', message);
  }

  private log(level: string, message: string) {
    if (console && console[level]) {
      console[level](`[${level}] Exceptionless: ${message}`);
    }
  }
}

                              

export class NullLog implements ILog {
  public info(message: string): void { }
  public warn(message: string): void { }
  public error(message: string): void { }
}

export interface IUserInfo {
  identity?: string;
  name?: string;
  data?: any;
}

                                                                                                         

export interface IEventPlugin {
  priority?: number;
  name?: string;
  run(context: EventPluginContext, next?: () => void): void;
}

                                                                                                                                                                                             

export class EventPluginContext {
  public cancelled: boolean;
  public client: ExceptionlessClient;
  public event: IEvent;
  public contextData: ContextData;

  constructor(client: ExceptionlessClient, event: IEvent, contextData?: ContextData) {
    this.client = client;
    this.event = event;
    this.contextData = contextData ? contextData : new ContextData();
  }

  public get log(): ILog {
    return this.client.config.log;
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

export class EventPluginManager {
  public static run(context: EventPluginContext, callback: (context?: EventPluginContext) => void): void {
    let wrap = function(plugin: IEventPlugin, next?: () => void): () => void {
      return () => {
        try {
          if (!context.cancelled) {
            plugin.run(context, next);
          }
        } catch (ex) {
          context.cancelled = true;
          context.log.error(`Error running plugin '${plugin.name}': ${ex.message}. Discarding Event.`);
        }

        if (context.cancelled && !!callback) {
          callback(context);
        }
      };
    };

    let plugins: IEventPlugin[] = context.client.config.plugins; // optimization for minifier.
    let wrappedPlugins: { (): void }[] = [];
    if (!!callback) {
      wrappedPlugins[plugins.length] = wrap({ name: 'cb', priority: 9007199254740992, run: callback }, null);
    }

    for (let index = plugins.length - 1; index > -1; index--) {
      wrappedPlugins[index] = wrap(plugins[index], !!callback || (index < plugins.length - 1) ? wrappedPlugins[index + 1] : null);
    }

    wrappedPlugins[0]();
  }

  public static addDefaultPlugins(config: Configuration): void {
    config.addPlugin(new ConfigurationDefaultsPlugin());
    config.addPlugin(new ErrorPlugin());
    config.addPlugin(new DuplicateCheckerPlugin());
    config.addPlugin(new ModuleInfoPlugin());
    config.addPlugin(new RequestInfoPlugin());
    config.addPlugin(new EnvironmentInfoPlugin());
    config.addPlugin(new SubmissionMethodPlugin());
  }
}

                                                                                                                                                

export class ReferenceIdPlugin implements IEventPlugin {
  public priority: number = 20;
  public name: string = 'ReferenceIdPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    if ((!context.event.reference_id || context.event.reference_id.length === 0) && context.event.type === 'error') {
      context.event.reference_id = Utils.guid().replace('-', '').substring(0, 10);
    }

    next && next();
  }
}

                                                                                                                                                                                                                                                                                                               

export class DefaultEventQueue implements IEventQueue {
  /**
   * The configuration object.
   * @type {Configuration}
   * @private
   */
  private _config: Configuration;

  /**
   * Suspends processing until the specified time.
   * @type {Date}
   * @private
   */
  private _suspendProcessingUntil: Date;

  /**
   * Discards queued items until the specified time.
   * @type {Date}
   * @private
   */
  private _discardQueuedItemsUntil: Date;

  /**
   * Returns true if the queue is processing.
   * @type {boolean}
   * @private
   */
  private _processingQueue: boolean = false;

  /**
   * Processes the queue every xx seconds.
   * @type {Timer}
   * @private
   */
  private _queueTimer: any;

  constructor(config: Configuration) {
    this._config = config;
  }

  public enqueue(event: IEvent): void {
    let config: Configuration = this._config; // Optimization for minifier.
    this.ensureQueueTimer();

    if (this.areQueuedItemsDiscarded()) {
      config.log.info('Queue items are currently being discarded. The event will not be queued.');
      return;
    }

    let key = `ex-q-${new Date().toJSON() }-${Utils.randomNumber() }`;
    config.log.info(`Enqueuing event: ${key} type=${event.type} ${!!event.reference_id ? 'refid=' + event.reference_id : ''}`);
    config.storage.save(key, event);
  }

  public process(isAppExiting?: boolean): void {
    function getEvents(events: { path: string, value: IEvent }[]): IEvent[] {
      let items: IEvent[] = [];
      for (let index = 0; index < events.length; index++) {
        items.push(events[index].value);
      }

      return items;
    }

    const queueNotProcessed: string = 'The queue will not be processed.'; // optimization for minifier.
    let config: Configuration = this._config; // Optimization for minifier.
    let log: ILog = config.log; // Optimization for minifier.

    this.ensureQueueTimer();

    if (this._processingQueue) {
      return;
    }

    log.info('Processing queue...');
    if (!config.enabled) {
      log.info(`Configuration is disabled. ${queueNotProcessed}`);
      return;
    }

    if (!config.isValid) {
      log.info(`Invalid Api Key. ${queueNotProcessed}`);
      return;
    }

    this._processingQueue = true;

    try {
      let events = config.storage.getList('ex-q', config.submissionBatchSize);
      if (!events || events.length === 0) {
        this._processingQueue = false;
        return;
      }

      log.info(`Sending ${events.length} events to ${config.serverUrl}.`);
      config.submissionClient.postEvents(getEvents(events), config, (response: SubmissionResponse) => {
        this.processSubmissionResponse(response, events);
        log.info('Finished processing queue.');
        this._processingQueue = false;
      }, isAppExiting);
    } catch (ex) {
      log.error(`Error processing queue: ${ex}`);
      this.suspendProcessing();
      this._processingQueue = false;
    }
  }

  public suspendProcessing(durationInMinutes?: number, discardFutureQueuedItems?: boolean, clearQueue?: boolean): void {
    let config: Configuration = this._config; // Optimization for minifier.

    if (!durationInMinutes || durationInMinutes <= 0) {
      durationInMinutes = 5;
    }

    config.log.info(`Suspending processing for ${durationInMinutes} minutes.`);
    this._suspendProcessingUntil = new Date(new Date().getTime() + (durationInMinutes * 60000));

    if (discardFutureQueuedItems) {
      this._discardQueuedItemsUntil = new Date(new Date().getTime() + (durationInMinutes * 60000));
    }

    if (clearQueue) {
      // Account is over the limit and we want to ensure that the sample size being sent in will contain newer errors.
      this.removeEvents(config.storage.getList('ex-q'));
    }
  }

  private areQueuedItemsDiscarded(): boolean {
    return this._discardQueuedItemsUntil && this._discardQueuedItemsUntil > new Date();
  }

  private ensureQueueTimer(): void {
    if (!this._queueTimer) {
      this._queueTimer = setInterval(() => this.onProcessQueue(), 10000);
    }
  }

  private isQueueProcessingSuspended(): boolean {
    return this._suspendProcessingUntil && this._suspendProcessingUntil > new Date();
  }

  private onProcessQueue(): void {
    if (!this.isQueueProcessingSuspended() && !this._processingQueue) {
      this.process();
    }
  }

  private processSubmissionResponse(response: SubmissionResponse, events: { path: string, value: IEvent }[]): void {
    const noSubmission: string = 'The event will not be submitted.'; // Optimization for minifier.
    let config: Configuration = this._config; // Optimization for minifier.
    let log: ILog = config.log; // Optimization for minifier.

    if (response.success) {
      log.info(`Sent ${events.length} events.`);
      this.removeEvents(events);
      return;
    }

    if (response.serviceUnavailable) {
      // You are currently over your rate limit or the servers are under stress.
      log.error('Server returned service unavailable.');
      this.suspendProcessing();
      return;
    }

    if (response.paymentRequired) {
      // If the organization over the rate limit then discard the event.
      log.info('Too many events have been submitted, please upgrade your plan.');
      this.suspendProcessing(null, true, true);
      return;
    }

    if (response.unableToAuthenticate) {
      // The api key was suspended or could not be authorized.
      log.info(`Unable to authenticate, please check your configuration. ${noSubmission}`);
      this.suspendProcessing(15);
      this.removeEvents(events);
      return;
    }

    if (response.notFound || response.badRequest) {
      // The service end point could not be found.
      log.error(`Error while trying to submit data: ${response.message}`);
      this.suspendProcessing(60 * 4);
      this.removeEvents(events);
      return;
    }

    if (response.requestEntityTooLarge) {
      let message = 'Event submission discarded for being too large.';
      if (config.submissionBatchSize > 1) {
        log.error(`${message} Retrying with smaller batch size.`);
        config.submissionBatchSize = Math.max(1, Math.round(config.submissionBatchSize / 1.5));
      } else {
        log.error(`${message} ${noSubmission}`);
        this.removeEvents(events);
      }

      return;
    }

    if (!response.success) {
      log.error(`Error submitting events: ${response.message || 'Please check the network tab for more info.'}`);
      this.suspendProcessing();
    }
  }

  private removeEvents(events: { path: string, value: IEvent }[]) {
    for (let index = 0; index < (events || []).length; index++) {
      this._config.storage.remove(events[index].path);
    }
  }
}

                                                                                     

export class InMemoryStorage<T> implements IStorage<T> {
  private _items: IStorageItem<T>[] = [];
  private _maxItems: number;

  constructor(maxItems?: number) {
    this._maxItems = maxItems > 0 ? maxItems : 250;
  }

  public save(path: string, value: T): boolean {
    if (!path || !value) {
      return false;
    }

    this.remove(path);
    if (this._items.push({ created: new Date().getTime(), path: path, value: value }) > this._maxItems) {
      this._items.shift();
    }

    return true;
  }

  public get(path: string): T {
    let item: IStorageItem<T> = path ? this.getList(`^${path}$`, 1)[0] : null;
    return item ? item.value : null;
  }

  public getList(searchPattern?: string, limit?: number): IStorageItem<T>[] {
    let items = this._items; // Optimization for minifier
    if (!searchPattern) {
      return items.slice(0, limit);
    }

    let regex = new RegExp(searchPattern);
    let results: IStorageItem<T>[] = [];
    for (let index = 0; index < items.length; index++) {
      if (regex.test(items[index].path)) {
        results.push(items[index]);

        if (results.length >= limit) {
          break;
        }
      }
    }

    return results;
  }

  public remove(path: string): void {
    if (path) {
      let item = this.getList(`^${path}$`, 1)[0];
      if (item) {
        this._items.splice(this._items.indexOf(item), 1);
      }
    }
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        

declare var XDomainRequest: { new (); create(); };

export class DefaultSubmissionClient implements ISubmissionClient {
  public configurationVersionHeader: string = 'x-exceptionless-configversion';

  public postEvents(events: IEvent[], config: Configuration, callback: (response: SubmissionResponse) => void, isAppExiting?: boolean): void {
    let data = JSON.stringify(events);
    let request = this.createRequest(config, 'POST', '/api/v2/events', data);
    let cb = this.createSubmissionCallback(config, callback);

    return config.submissionAdapter.sendRequest(request, cb, isAppExiting);
  }

  public postUserDescription(referenceId: string, description: IUserDescription, config: Configuration, callback: (response: SubmissionResponse) => void): void {
    let path = `/api/v2/events/by-ref/${encodeURIComponent(referenceId) }/user-description`;
    let data = JSON.stringify(description);
    let request = this.createRequest(config, 'POST', path, data);
    let cb = this.createSubmissionCallback(config, callback);

    return config.submissionAdapter.sendRequest(request, cb);
  }

  public getSettings(config: Configuration, callback: (response: SettingsResponse) => void): void {
    let request = this.createRequest(config, 'GET', '/api/v2/projects/config');
    let cb = (status, message, data?, headers?) => {
      if (status !== 200) {
        return callback(new SettingsResponse(false, null, -1, null, message));
      }

      let settings: IClientConfiguration;
      try {
        settings = JSON.parse(data);
      } catch (e) {
        config.log.error(`Unable to parse settings: '${data}'`);
      }

      if (!settings || isNaN(settings.version)) {
        return callback(new SettingsResponse(false, null, -1, null, 'Invalid configuration settings.'));
      }

      callback(new SettingsResponse(true, settings.settings || {}, settings.version));
    };

    return config.submissionAdapter.sendRequest(request, cb);
  }

  private createRequest(config: Configuration, method: string, path: string, data: string = null): SubmissionRequest {
    return {
      method,
      path,
      data,
      serverUrl: config.serverUrl,
      apiKey: config.apiKey,
      userAgent: config.userAgent
    };
  }

  private createSubmissionCallback(config: Configuration, callback: (response: SubmissionResponse) => void) {
    return (status, message, data?, headers?) => {
      let settingsVersion: number = headers && parseInt(headers[this.configurationVersionHeader], 10);
      SettingsManager.checkVersion(settingsVersion, config);

      callback(new SubmissionResponse(status, message));
    };
  }
}

export class Utils {
  public static addRange<T>(target: T[], ...values: T[]) {
    if (!target) {
      target = [];
    }

    if (!values || values.length === 0) {
      return target;
    }

    for (let index = 0; index < values.length; index++) {
      if (values[index] && target.indexOf(values[index]) < 0) {
        target.push(values[index]);
      }
    }

    return target;
  }

  public static getHashCode(source: string): number {
    if (!source || source.length === 0) {
      return 0;
    }

    let hash: number = 0;
    for (let index = 0; index < source.length; index++) {
      let character = source.charCodeAt(index);
      hash = ((hash << 5) - hash) + character;
      hash |= 0;
    }

    return hash;
  }

  public static getCookies(cookies: string, exclusions?: string[]): Object {
    let result: Object = {};

    let parts: string[] = (cookies || '').split('; ');
    for (let index = 0; index < parts.length; index++) {
      let cookie: string[] = parts[index].split('=');
      if (!Utils.isMatch(cookie[0], exclusions)) {
        result[cookie[0]] = cookie[1];
      }
    }

    return !Utils.isEmpty(result) ? result : null;
  }

  public static guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public static merge(defaultValues: Object, values: Object) {
    let result: Object = {};

    for (let key in defaultValues || {}) {
      if (!!defaultValues[key]) {
        result[key] = defaultValues[key];
      }
    }

    for (let key in values || {}) {
      if (!!values[key]) {
        result[key] = values[key];
      }
    }

    return result;
  }

  public static parseVersion(source: string): string {
    if (!source) {
      return null;
    }

    let versionRegex = /(v?((\d+)\.(\d+)(\.(\d+))?)(?:-([\dA-Za-z\-]+(?:\.[\dA-Za-z\-]+)*))?(?:\+([\dA-Za-z\-]+(?:\.[\dA-Za-z\-]+)*))?)/;
    let matches = versionRegex.exec(source);
    if (matches && matches.length > 0) {
      return matches[0];
    }

    return null;
  }

  public static parseQueryString(query: string, exclusions?: string[]) {
    if (!query || query.length === 0) {
      return null;
    }

    let pairs: string[] = query.split('&');
    if (pairs.length === 0) {
      return null;
    }

    let result: Object = {};
    for (let index = 0; index < pairs.length; index++) {
      let pair = pairs[index].split('=');
      if (!Utils.isMatch(pair[0], exclusions)) {
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    }

    return !Utils.isEmpty(result) ? result : null;
  }

  public static randomNumber(): number {
    return Math.floor(Math.random() * 9007199254740992);
  }

  /**
   * Checks to see if a value matches a pattern.
   * @param input the value to check against the @pattern.
   * @param pattern The pattern to check, supports wild cards (*).
   */
  public static isMatch(input: string, patterns: string[]): boolean {
    if (!input || typeof input !== 'string') {
      return false;
    }

    let trim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
    return (patterns || []).some(pattern => {
      if (!pattern) {
        return false;
      }

      pattern = pattern.toLowerCase().replace(trim, '');
      input = input.toLowerCase().replace(trim, '');

      if (pattern.length <= 0) {
        return false;
      }

      let startsWithWildcard: boolean = pattern[0] === '*';
      if (startsWithWildcard) {
        pattern = pattern.slice(1);
      }

      let endsWithWildcard: boolean = pattern[pattern.length - 1] === '*';
      if (endsWithWildcard) {
        pattern = pattern.substring(0, pattern.length - 1);
      }

      if (startsWithWildcard && endsWithWildcard) {
        return input.indexOf(pattern) !== -1;
      }

      if (startsWithWildcard) {
        let lastIndexOf = input.lastIndexOf(pattern);
        return lastIndexOf !== -1 && lastIndexOf === (input.length - pattern.length);
      }

      if (endsWithWildcard) {
        return input.indexOf(pattern) === 0;
      }

      return input === pattern;
    });
  }

  public static isEmpty(input: Object) {
    return input === null || (typeof (input) === 'object' && Object.keys(input).length === 0);
  }

  /**
   * Stringifys an object with optional exclusions and max depth.
   * @param data The data object to add.
   * @param exclusions Any property names that should be excluded.
   * @param maxDepth The max depth of the object to include.
   */
  public static stringify(data: any, exclusions?: string[], maxDepth?: number): string {
    function stringifyImpl(obj: any, excludedKeys: string[]): string {
      let cache: string[] = [];
      return JSON.stringify(obj, function(key: string, value: any) {
        if (Utils.isMatch(key, excludedKeys)) {
          return;
        }

        if (typeof value === 'object' && !!value) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return;
          }

          cache.push(value);
        }

        return value;
      });
    }

    if (({}).toString.call(data) === '[object Object]') {
      let flattened = {};
      /* tslint:disable:forin */
      for (let prop in data) {
        let value = data[prop];
        if (value === data) {
          continue;
        }
        flattened[prop] = data[prop];
      }
      /* tslint:enable:forin */

      return stringifyImpl(flattened, exclusions);
    }

    if (({}).toString.call(data) === '[object Array]') {
      let result = [];
      for (let index = 0; index < data.length; index++) {
        result[index] = JSON.parse(stringifyImpl(data[index], exclusions));
      }

      return JSON.stringify(result);
    }

    return stringifyImpl(data, exclusions);
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           

export class Configuration implements IConfigurationSettings {
  /**
   * The default configuration settings that are applied to new configuration instances.
   * @type {IConfigurationSettings}
   * @private
   */
  private static _defaultSettings: IConfigurationSettings = null;

  /**
   * A default list of tags that will automatically be added to every
   * report submitted to the server.
   *
   * @type {Array}
   */
  public defaultTags: string[] = [];

  /**
   * A default list of of extended data objects that will automatically
   * be added to every report submitted to the server.
   *
   * @type {{}}
   */
  public defaultData: Object = {};

  /**
   * Whether the client is currently enabled or not. If it is disabled,
   * submitted errors will be discarded and no data will be sent to the server.
   *
   * @returns {boolean}
   */
  public enabled: boolean = true;

  public environmentInfoCollector: IEnvironmentInfoCollector;
  public errorParser: IErrorParser;
  public lastReferenceIdManager: ILastReferenceIdManager = new DefaultLastReferenceIdManager();
  public log: ILog;
  public moduleCollector: IModuleCollector;
  public requestInfoCollector: IRequestInfoCollector;

  /**
   * Maximum number of events that should be sent to the server together in a batch. (Defaults to 50)
   */
  public submissionBatchSize: number;
  public submissionAdapter: ISubmissionAdapter;
  public submissionClient: ISubmissionClient;

  /**
   * Contains a dictionary of custom settings that can be used to control
   * the client and will be automatically updated from the server.
   */
  public settings: Object = {};

  public storage: IStorage<Object>;

  public queue: IEventQueue;

  /**
   * The list of plugins that will be used in this configuration.
   * @type {Array}
   * @private
   */
  private _plugins: IEventPlugin[] = [];

  constructor(configSettings?: IConfigurationSettings) {
    function inject(fn: any) {
      return typeof fn === 'function' ? fn(this) : fn;
    }

    configSettings = Utils.merge(Configuration.defaults, configSettings);

    this.log = inject(configSettings.log) || new NullLog();
    this.apiKey = configSettings.apiKey;
    this.serverUrl = configSettings.serverUrl;

    this.environmentInfoCollector = inject(configSettings.environmentInfoCollector);
    this.errorParser = inject(configSettings.errorParser);
    this.lastReferenceIdManager = inject(configSettings.lastReferenceIdManager) || new DefaultLastReferenceIdManager();
    this.moduleCollector = inject(configSettings.moduleCollector);
    this.requestInfoCollector = inject(configSettings.requestInfoCollector);
    this.submissionBatchSize = inject(configSettings.submissionBatchSize) || 50;
    this.submissionAdapter = inject(configSettings.submissionAdapter);
    this.submissionClient = inject(configSettings.submissionClient) || new DefaultSubmissionClient();
    this.storage = inject(configSettings.storage) || new InMemoryStorage<any>();
    this.queue = inject(configSettings.queue) || new DefaultEventQueue(this);

    SettingsManager.applySavedServerSettings(this);
    EventPluginManager.addDefaultPlugins(this);
  }

  /**
   * The API key that will be used when sending events to the server.
   * @type {string}
   * @private
   */
  private _apiKey: string;

  /**
   * The API key that will be used when sending events to the server.
   * @returns {string}
   */
  public get apiKey(): string {
    return this._apiKey;
  }

  /**
   * The API key that will be used when sending events to the server.
   * @param value
   */
  public set apiKey(value: string) {
    this._apiKey = value || null;
    this.log.info(`apiKey: ${this._apiKey}`);
  }

  /**
   * Returns true if the apiKey is valid.
   * @returns {boolean}
   */
  public get isValid(): boolean {
    return !!this.apiKey && this.apiKey.length >= 10;
  }

  /**
   * The server url that all events will be sent to.
   * @type {string}
   * @private
   */
  private _serverUrl: string = 'https://collector.exceptionless.io';

  /**
   * The server url that all events will be sent to.
   * @returns {string}
   */
  public get serverUrl(): string {
    return this._serverUrl;
  }

  /**
   * The server url that all events will be sent to.
   * @param value
   */
  public set serverUrl(value: string) {
    if (!!value) {
      this._serverUrl = value;
      this.log.info(`serverUrl: ${this._serverUrl}`);
    }
  }

  /**
   * A list of exclusion patterns.
   * @type {Array}
   * @private
   */
  private _dataExclusions: string[] = [];

  /**
   *  A list of exclusion patterns that will automatically remove any data that
   *  matches them from any data submitted to the server.
   *
   *  For example, entering CreditCard will remove any extended data properties,
   *  form fields, cookies and query parameters from the report.
   *
   * @returns {string[]}
   */
  public get dataExclusions(): string[] {
    let exclusions: string = this.settings['@@DataExclusions'];
    return this._dataExclusions.concat(exclusions && exclusions.split(',') || []);
  }

  /**
   * Add items to the list of exclusion patterns that will automatically remove any
   * data that matches them from any data submitted to the server.
   *
   * For example, entering CreditCard will remove any extended data properties, form
   * fields, cookies and query parameters from the report.
   *
   * @param exclusions
   */
  public addDataExclusions(...exclusions: string[]) {
    this._dataExclusions = Utils.addRange<string>(this._dataExclusions, ...exclusions);
  }

  /**
   * The list of plugins that will be used in this configuration.
   * @returns {IEventPlugin[]}
   */
  public get plugins(): IEventPlugin[] {
    return this._plugins.sort((p1: IEventPlugin, p2: IEventPlugin) => {
      return (p1.priority < p2.priority) ? -1 : (p1.priority > p2.priority) ? 1 : 0;
    });
  }

  /**
   * Register an plugin to be used in this configuration.
   * @param plugin
   */
  public addPlugin(plugin: IEventPlugin): void;

  /**
   * Register an plugin to be used in this configuration.
   * @param name The name used to identify the plugin.
   * @param priority Used to determine plugins priority.
   * @param pluginAction A function that is run.
   */
  public addPlugin(name: string, priority: number, pluginAction: (context: EventPluginContext, next?: () => void) => void): void;
  public addPlugin(pluginOrName: IEventPlugin | string, priority?: number, pluginAction?: (context: EventPluginContext, next?: () => void) => void): void {
    let plugin: IEventPlugin = !!pluginAction ? { name: <string>pluginOrName, priority: priority, run: pluginAction } : <IEventPlugin>pluginOrName;
    if (!plugin || !plugin.run) {
      this.log.error('Add plugin failed: Run method not defined');
      return;
    }

    if (!plugin.name) {
      plugin.name = Utils.guid();
    }

    if (!plugin.priority) {
      plugin.priority = 0;
    }

    let pluginExists: boolean = false;
    let plugins = this._plugins; // optimization for minifier.
    for (let index = 0; index < plugins.length; index++) {
      if (plugins[index].name === plugin.name) {
        pluginExists = true;
        break;
      }
    }

    if (!pluginExists) {
      plugins.push(plugin);
    }
  }

  /**
   * Remove the plugin from this configuration.
   * @param plugin
   */
  public removePlugin(plugin: IEventPlugin): void;

  /**
   * Remove an plugin by key from this configuration.
   * @param name
   */
  public removePlugin(name: string): void;
  public removePlugin(pluginOrName: IEventPlugin | string): void {
    let name: string = typeof pluginOrName === 'string' ? pluginOrName : pluginOrName.name;
    if (!name) {
      this.log.error('Remove plugin failed: Plugin name not defined');
      return;
    }

    let plugins = this._plugins; // optimization for minifier.
    for (let index = 0; index < plugins.length; index++) {
      if (plugins[index].name === name) {
        plugins.splice(index, 1);
        break;
      }
    }
  }

  /**
   * Automatically set the application version for events.
   * @param version
   */
  public setVersion(version: string): void {
    if (!!version) {
      this.defaultData['@version'] = version;
    }
  }

  public setUserIdentity(userInfo: IUserInfo): void;
  public setUserIdentity(identity: string): void;
  public setUserIdentity(identity: string, name: string): void;
  public setUserIdentity(userInfoOrIdentity: IUserInfo | string, name?: string): void {
    const USER_KEY: string = '@user'; // optimization for minifier.
    let userInfo: IUserInfo = typeof userInfoOrIdentity !== 'string' ? userInfoOrIdentity : { identity: userInfoOrIdentity, name: name };

    let shouldRemove: boolean = !userInfo || (!userInfo.identity && !userInfo.name);
    if (shouldRemove) {
      delete this.defaultData[USER_KEY];
    } else {
      this.defaultData[USER_KEY] = userInfo;
    }

    this.log.info(`user identity: ${shouldRemove ? 'null' : userInfo.identity}`);
  }

  /**
   * Used to identify the client that sent the events to the server.
   * @returns {string}
   */
  public get userAgent(): string {
    return 'exceptionless-js/1.0.0.0';
  }

  /**
   * Automatically set a reference id for error events.
   */
  public useReferenceIds(): void {
    this.addPlugin(new ReferenceIdPlugin());
  }

  // TODO: Support a min log level.
  public useDebugLogger(): void {
    this.log = new ConsoleLog();
  }

  /**
   * The default configuration settings that are applied to new configuration instances.
   * @returns {IConfigurationSettings}
   */
  public static get defaults() {
    if (Configuration._defaultSettings === null) {
      Configuration._defaultSettings = {};
    }

    return Configuration._defaultSettings;
  }
}

                                                                                                                                                                                                                                                                                                               

export class EventBuilder {
  public target: IEvent;
  public client: ExceptionlessClient;
  public pluginContextData: ContextData;

  private _validIdentifierErrorMessage: string = 'must contain between 8 and 100 alphanumeric or \'-\' characters.'; // optimization for minifier.

  constructor(event: IEvent, client: ExceptionlessClient, pluginContextData?: ContextData) {
    this.target = event;
    this.client = client;
    this.pluginContextData = pluginContextData || new ContextData();
  }

  public setType(type: string): EventBuilder {
    if (!!type) {
      this.target.type = type;
    }

    return this;
  }

  public setSource(source: string): EventBuilder {
    if (!!source) {
      this.target.source = source;
    }

    return this;
  }

  public setSessionId(sessionId: string): EventBuilder {
    if (!this.isValidIdentifier(sessionId)) {
      throw new Error(`SessionId ${this._validIdentifierErrorMessage}`);
    }

    this.target.session_id = sessionId;
    return this;
  }

  public setReferenceId(referenceId: string): EventBuilder {
    if (!this.isValidIdentifier(referenceId)) {
      throw new Error(`ReferenceId ${this._validIdentifierErrorMessage}`);
    }

    this.target.reference_id = referenceId;
    return this;
  }

  public setMessage(message: string): EventBuilder {
    if (!!message) {
      this.target.message = message;
    }

    return this;
  }

  public setGeo(latitude: number, longitude: number): EventBuilder {
    if (latitude < -90.0 || latitude > 90.0) {
      throw new Error('Must be a valid latitude value between -90.0 and 90.0.');
    }

    if (longitude < -180.0 || longitude > 180.0) {
      throw new Error('Must be a valid longitude value between -180.0 and 180.0.');
    }

    this.target.geo = `${latitude},${longitude}`;
    return this;
  }

  public setUserIdentity(userInfo: IUserInfo): EventBuilder;
  public setUserIdentity(identity: string): EventBuilder;
  public setUserIdentity(identity: string, name: string): EventBuilder;
  public setUserIdentity(userInfoOrIdentity: IUserInfo | string, name?: string): EventBuilder {
    let userInfo = typeof userInfoOrIdentity !== 'string' ? userInfoOrIdentity : { identity: userInfoOrIdentity, name: name };
    if (!userInfo || (!userInfo.identity && !userInfo.name)) {
      return this;
    }

    this.setProperty('@user', userInfo);
    return this;
  }

  public setValue(value: number): EventBuilder {
    if (!!value) {
      this.target.value = value;
    }

    return this;
  }

  public addTags(...tags: string[]): EventBuilder {
    this.target.tags = Utils.addRange<string>(this.target.tags, ...tags);
    return this;
  }

  /**
   * Adds the object to extended data. Uses @excludedPropertyNames
   * to exclude data from being included in the event.
   * @param name The data object to add.
   * @param value The name of the object to add.
   * @param maxDepth The max depth of the object to include.
   * @param excludedPropertyNames Any property names that should be excluded.
   */
  public setProperty(name: string, value: any, maxDepth?: number, excludedPropertyNames?: string[]): EventBuilder {
    if (!name || (value === undefined || value == null)) {
      return this;
    }

    if (!this.target.data) {
      this.target.data = {};
    }

    let result = JSON.parse(Utils.stringify(value, this.client.config.dataExclusions.concat(excludedPropertyNames || []), maxDepth));
    if (!Utils.isEmpty(result)) {
      this.target.data[name] = result;
    }

    return this;
  }

  public markAsCritical(critical: boolean): EventBuilder {
    if (critical) {
      this.addTags('Critical');
    }

    return this;
  }

  public addRequestInfo(request: Object): EventBuilder {
    if (!!request) {
      this.pluginContextData['@request'] = request;
    }

    return this;
  }

  public submit(callback?: (context: EventPluginContext) => void): void {
    this.client.submitEvent(this.target, this.pluginContextData, callback);
  }

  private isValidIdentifier(value: string): boolean {
    if (!value) {
      return true;
    }

    if (value.length < 8 || value.length > 100) {
      return false;
    }

    for (var index = 0; index < value.length; index++) {
      let code = value.charCodeAt(index);
      let isDigit = (code >= 48) && (code <= 57);
      let isLetter = ((code >= 65) && (code <= 90)) || ((code >= 97) && (code <= 122));
      let isMinus = code === 45;

      if (!(isDigit || isLetter) && !isMinus) {
        return false;
      }
    }

    return true;
  }
}

export interface IUserDescription {
  email_address?: string;
  description?: string;
  data?: any;
}

export class ContextData {
  public setException(exception: Error): void {
    if (exception) {
      this['@@_Exception'] = exception;
    }
  }

  public get hasException(): boolean {
    return !!this['@@_Exception'];
  }

  public getException(): Error {
    return this['@@_Exception'] || null;
  }

  public markAsUnhandledError(): void {
    this['@@_IsUnhandledError'] = true;
  }

  public get isUnhandledError(): boolean {
    return !!this['@@_IsUnhandledError'];
  }

  public setSubmissionMethod(method: string): void {
    if (method) {
      this['@@_SubmissionMethod'] = method;
    }
  }

  public getSubmissionMethod(): string {
    return this['@@_SubmissionMethod'] || null;
  }
}

export class SubmissionResponse {
  success: boolean = false;
  badRequest: boolean = false;
  serviceUnavailable: boolean = false;
  paymentRequired: boolean = false;
  unableToAuthenticate: boolean = false;
  notFound: boolean = false;
  requestEntityTooLarge: boolean = false;
  statusCode: number;
  message: string;

  constructor(statusCode: number, message?: string) {
    this.statusCode = statusCode;
    this.message = message;

    this.success = statusCode >= 200 && statusCode <= 299;
    this.badRequest = statusCode === 400;
    this.serviceUnavailable = statusCode === 503;
    this.paymentRequired = statusCode === 402;
    this.unableToAuthenticate = statusCode === 401 || statusCode === 403;
    this.notFound = statusCode === 404;
    this.requestEntityTooLarge = statusCode === 413;
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       

export class ExceptionlessClient {
  /**
   * The default ExceptionlessClient instance.
   * @type {ExceptionlessClient}
   * @private
   */
  private static _instance: ExceptionlessClient = null;

  public config: Configuration;

  constructor();
  constructor(settings: IConfigurationSettings);
  constructor(apiKey: string, serverUrl?: string);
  constructor(settingsOrApiKey?: IConfigurationSettings | string, serverUrl?: string) {
    if (typeof settingsOrApiKey !== 'object') {
      this.config = new Configuration(settingsOrApiKey);
    } else {
      this.config = new Configuration({ apiKey: <string>settingsOrApiKey, serverUrl: serverUrl });
    }
  }

  public createException(exception: Error): EventBuilder {
    let pluginContextData = new ContextData();
    pluginContextData.setException(exception);
    return this.createEvent(pluginContextData).setType('error');
  }

  public submitException(exception: Error, callback?: (context: EventPluginContext) => void): void {
    this.createException(exception).submit(callback);
  }

  public createUnhandledException(exception: Error, submissionMethod?: string): EventBuilder {
    let builder = this.createException(exception);
    builder.pluginContextData.markAsUnhandledError();
    builder.pluginContextData.setSubmissionMethod(submissionMethod);

    return builder;
  }

  public submitUnhandledException(exception: Error, submissionMethod?: string, callback?: (context: EventPluginContext) => void) {
    this.createUnhandledException(exception, submissionMethod).submit(callback);
  }

  public createFeatureUsage(feature: string): EventBuilder {
    return this.createEvent().setType('usage').setSource(feature);
  }

  public submitFeatureUsage(feature: string, callback?: (context: EventPluginContext) => void): void {
    this.createFeatureUsage(feature).submit(callback);
  }

  public createLog(message: string): EventBuilder;
  public createLog(source: string, message: string): EventBuilder;
  public createLog(source: string, message: string, level: string): EventBuilder;
  public createLog(sourceOrMessage: string, message?: string, level?: string): EventBuilder {
    let builder = this.createEvent().setType('log');

    if (message && level) {
      builder = builder.setSource(sourceOrMessage).setMessage(message).setProperty('@level', level);
    } else if (message) {
      builder = builder.setSource(sourceOrMessage).setMessage(message);
    } else {
      // TODO: Look into using https://www.stevefenton.co.uk/Content/Blog/Date/201304/Blog/Obtaining-A-Class-Name-At-Runtime-In-TypeScript/
      let caller: any = arguments.callee.caller;
      builder = builder.setSource(caller && caller.name).setMessage(sourceOrMessage);
    }

    return builder;
  }

  public submitLog(message: string): void;
  public submitLog(source: string, message: string): void;
  public submitLog(source: string, message: string, level: string, callback?: (context: EventPluginContext) => void): void;
  public submitLog(sourceOrMessage: string, message?: string, level?: string, callback?: (context: EventPluginContext) => void): void {
    this.createLog(sourceOrMessage, message, level).submit(callback);
  }

  public createNotFound(resource: string): EventBuilder {
    return this.createEvent().setType('404').setSource(resource);
  }

  public submitNotFound(resource: string, callback?: (context: EventPluginContext) => void): void {
    this.createNotFound(resource).submit(callback);
  }

  public createSessionStart(sessionId: string): EventBuilder {
    return this.createEvent().setType('start').setSessionId(sessionId);
  }

  public submitSessionStart(sessionId: string, callback?: (context: EventPluginContext) => void): void {
    this.createSessionStart(sessionId).submit(callback);
  }

  public createSessionEnd(sessionId: string): EventBuilder {
    return this.createEvent().setType('end').setSessionId(sessionId);
  }

  public submitSessionEnd(sessionId: string, callback?: (context: EventPluginContext) => void): void {
    this.createSessionEnd(sessionId).submit(callback);
  }

  public createEvent(pluginContextData?: ContextData): EventBuilder {
    return new EventBuilder({ date: new Date() }, this, pluginContextData);
  }

  /**
   * Submits the event to be sent to the server.
   * @param event The event data.
   * @param pluginContextData Any contextual data objects to be used by Exceptionless plugins to gather default information for inclusion in the report information.
   * @param callback
   */
  public submitEvent(event: IEvent, pluginContextData?: ContextData, callback?: (context: EventPluginContext) => void): void {
    function cancelled(context: EventPluginContext) {
      if (!!context) {
        context.cancelled = true;
      }

      return !!callback && callback(context);
    }

    let context = new EventPluginContext(this, event, pluginContextData);
    if (!event) {
      return cancelled(context);
    }

    if (!this.config.enabled) {
      this.config.log.info('Event submission is currently disabled.');
      return cancelled(context);
    }

    if (!event.data) {
      event.data = {};
    }

    if (!event.tags || !event.tags.length) {
      event.tags = [];
    }

    EventPluginManager.run(context, function(ctx: EventPluginContext) {
      let ev = ctx.event;
      if (!ctx.cancelled) {
        // ensure all required data
        if (!ev.type || ev.type.length === 0) {
          ev.type = 'log';
        }

        if (!ev.date) {
          ev.date = new Date();
        }

        let config = ctx.client.config;
        config.queue.enqueue(ev);

        if (ev.reference_id && ev.reference_id.length > 0) {
          ctx.log.info(`Setting last reference id '${ev.reference_id}'`);
          config.lastReferenceIdManager.setLast(ev.reference_id);
        }
      }

      !!callback && callback(ctx);
    });
  }

  /**
   * Updates the user's email address and description of an event for the specified reference id.
   * @param referenceId The reference id of the event to update.
   * @param email The user's email address to set on the event.
   * @param description The user's description of the event.
   * @param callback The submission response.
   */
  public updateUserEmailAndDescription(referenceId: string, email: string, description: string, callback?: (response: SubmissionResponse) => void) {
    if (!referenceId || !email || !description || !this.config.enabled) {
      return !!callback && callback(new SubmissionResponse(500, 'cancelled'));
    }

    let userDescription: IUserDescription = { email_address: email, description: description };
    this.config.submissionClient.postUserDescription(referenceId, userDescription, this.config, (response: SubmissionResponse) => {
      if (!response.success) {
        this.config.log.error(`Failed to submit user email and description for event '${referenceId}': ${response.statusCode} ${response.message}`);
      }

      !!callback && callback(response);
    });
  }

  /**
   * Gets the last event client id that was submitted to the server.
   * @returns {string} The event client id.
   */
  public getLastReferenceId(): string {
    return this.config.lastReferenceIdManager.getLast();
  }

  /**
   * The default ExceptionlessClient instance.
   * @type {ExceptionlessClient}
   */
  public static get default() {
    if (ExceptionlessClient._instance === null) {
      ExceptionlessClient._instance = new ExceptionlessClient(null);
    }

    return ExceptionlessClient._instance;
  }
}

export interface IModule {
  data?: any;

  module_id?: number;
  name?: string;
  version?: string;
  is_entry?: boolean;
  created_date?: Date;
  modified_date?: Date;
}

export interface IRequestInfo {
  user_agent?: string;
  http_method?: string;
  is_secure?: boolean;
  host?: string;
  port?: number;
  path?: string;
  referrer?: string;
  client_ip_address?: string;
  cookies?: any;
  post_data?: any;
  query_string?: any;
  data?: any;
}

export interface IEnvironmentInfo {
  processor_count?: number;
  total_physical_memory?: number;
  available_physical_memory?: number;
  command_line?: string;
  process_name?: string;
  process_id?: string;
  process_memory_size?: number;
  thread_id?: string;
  architecture?: string;
  o_s_name?: string;
  o_s_version?: string;
  ip_address?: string;
  machine_name?: string;
  install_id?: string;
  runtime_version?: string;
  data?: any;
}

export interface IParameter {
  data?: any;
  generic_arguments?: string[];

  name?: string;
  type?: string;
  type_namespace?: string;
}

                                          

export interface IMethod {
  data?: any;
  generic_arguments?: string[];
  parameters?: IParameter[];

  is_signature_target?: boolean;
  declaring_namespace?: string;
  declaring_type?: string;
  name?: string;
  module_id?: number;
}

                                    

export interface IStackFrame extends IMethod {
  file_name?: string;
  line_number?: number;
  column?: number;
}

                                                                                 

export interface IInnerError {
  message?: string;
  type?: string;
  code?: string;
  data?: any;
  inner?: IInnerError;
  stack_trace?: IStackFrame[];
  target_method?: IMethod;
}

                                                                                                                                                

export class ConfigurationDefaultsPlugin implements IEventPlugin {
  public priority: number = 10;
  public name: string = 'ConfigurationDefaultsPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    let config = context.client.config;
    let defaultTags: string[] = config.defaultTags || [];
    for (let index = 0; index < defaultTags.length; index++) {
      let tag = defaultTags[index];
      if (!!tag && context.event.tags.indexOf(tag) < 0) {
        context.event.tags.push(tag);
      }
    }

    let defaultData: Object = config.defaultData || {};
    for (let key in defaultData) {
      if (!!defaultData[key]) {
        let result = JSON.parse(Utils.stringify(defaultData[key], config.dataExclusions));
        if (!Utils.isEmpty(result)) {
          context.event.data[key] = result;
        }
      }
    }

    next && next();
  }
}

                                                                                                                                                

export class ErrorPlugin implements IEventPlugin {
  public priority: number = 30;
  public name: string = 'ErrorPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const ERROR_KEY: string = '@error'; // optimization for minifier.
    let ignoredProperties: string[] = [
      'arguments',
      'column',
      'columnNumber',
      'description',
      'fileName',
      'message',
      'name',
      'number',
      'line',
      'lineNumber',
      'opera#sourceloc',
      'sourceId',
      'sourceURL',
      'stack',
      'stackArray',
      'stacktrace'
    ];

    let exception = context.contextData.getException();
    if (!!exception) {
      context.event.type = 'error';

      if (!context.event.data[ERROR_KEY]) {
        let config = context.client.config;
        let parser = config.errorParser;
        if (!parser) {
          throw new Error('No error parser was defined.');
        }

        let result = parser.parse(context, exception);
        if (!!result) {
          let additionalData = JSON.parse(Utils.stringify(exception, config.dataExclusions.concat(ignoredProperties)));
          if (!Utils.isEmpty(additionalData)) {
            if (!result.data) {
              result.data = {};
            }
            result.data['@ext'] = additionalData;
          }

          context.event.data[ERROR_KEY] = result;
        }
      }
    }

    next && next();
  }
}

                                                                                                                                                           

export class ModuleInfoPlugin implements IEventPlugin {
  public priority: number = 50;
  public name: string = 'ModuleInfoPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const ERROR_KEY: string = '@error'; // optimization for minifier.

    let collector = context.client.config.moduleCollector;
    if (context.event.data[ERROR_KEY] && !context.event.data['@error'].modules && !!collector) {
      let modules: IModule[] = collector.getModules(context);
      if (modules && modules.length > 0) {
        context.event.data[ERROR_KEY].modules = modules;
      }
    }

    next && next();
  }
}

                                                                                                                                                                     

export class RequestInfoPlugin implements IEventPlugin {
  public priority: number = 70;
  public name: string = 'RequestInfoPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const REQUEST_KEY: string = '@request'; // optimization for minifier.

    let collector = context.client.config.requestInfoCollector;
    if (!context.event.data[REQUEST_KEY] && !!collector) {
      let requestInfo: IRequestInfo = collector.getRequestInfo(context);
      if (!!requestInfo) {
        context.event.data[REQUEST_KEY] = requestInfo;
      }
    }

    next && next();
  }
}

                                                                                                                                                                             

export class EnvironmentInfoPlugin implements IEventPlugin {
  public priority: number = 80;
  public name: string = 'EnvironmentInfoPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    const ENVIRONMENT_KEY: string = '@environment'; // optimization for minifier.

    let collector = context.client.config.environmentInfoCollector;
    if (!context.event.data[ENVIRONMENT_KEY] && collector) {
      let environmentInfo: IEnvironmentInfo = collector.getEnvironmentInfo(context);
      if (!!environmentInfo) {
        context.event.data[ENVIRONMENT_KEY] = environmentInfo;
      }
    }

    next && next();
  }
}

                                                                                                           

export class SubmissionMethodPlugin implements IEventPlugin {
  public priority: number = 100;
  public name: string = 'SubmissionMethodPlugin';

  public run(context: EventPluginContext, next?: () => void): void {
    let submissionMethod: string = context.contextData.getSubmissionMethod();
    if (!!submissionMethod) {
      context.event.data['@submission_method'] = submissionMethod;
    }

    next && next();
  }
}

                                                                                                                                                                                                                                                   

const ERROR_KEY: string = '@error';
const WINDOW_MILLISECONDS = 2000;
const MAX_QUEUE_LENGTH = 10;

export class DuplicateCheckerPlugin implements IEventPlugin {
  public priority: number = 40;
  public name: string = 'DuplicateCheckerPlugin';

  private recentlyProcessedErrors: TimestampedHash[] = [];

  public run(context: EventPluginContext, next?: () => void): void {
    if (context.event.type === 'error') {
      let error = context.event.data[ERROR_KEY];
      let isDuplicate = this.checkDuplicate(error, context.log);
      if (isDuplicate) {
        context.cancelled = true;
        return;
      }
    }

    next && next();
  }

  private getNow() {
    return Date.now();
  }

  private checkDuplicate(error: IInnerError, log: ILog): boolean {
    function getHashCodeForError(err: IInnerError): number {
      if (!err.stack_trace) {
        return null;
      }

      return Utils.getHashCode(JSON.stringify(err.stack_trace));
    }

    let now = this.getNow();
    let repeatWindow = now - WINDOW_MILLISECONDS;
    let hashCode: number;
    while (error) {
      hashCode = getHashCodeForError(error);

      // make sure that we don't process the same error multiple times within the repeat window
      if (hashCode && this.recentlyProcessedErrors.some(h =>
        h.hash === hashCode && h.timestamp >= repeatWindow)) {
        log.info(`Ignoring duplicate error event: hash=${hashCode}`);
        return true;
      }

      // add this exception to our list of recent errors that we have processed
      this.recentlyProcessedErrors.push({ hash: hashCode, timestamp: now });

      // only keep the last 10 recent errors
      while (this.recentlyProcessedErrors.length > MAX_QUEUE_LENGTH) {
        this.recentlyProcessedErrors.shift();
      }

      error = error.inner;
    }

    return false;
  }
}

interface TimestampedHash {
  hash: number;
  timestamp: number;
}

                                                                                 

export interface IError extends IInnerError {
  modules?: IModule[];
}

export interface IStorageItem<T> {
  created: number;
  path: string;
  value: T;
}

export interface SubmissionCallback {
  (status: number, message: string, data?: string, headers?: Object): void;
}

export interface SubmissionRequest {
  serverUrl: string;
  apiKey: string;
  userAgent: string;
  method: string;
  path: string;
  data: string;
}

export class SettingsResponse {
  success: boolean = false;
  settings: any;
  settingsVersion: number = -1;
  message: string;
  exception: any;

  constructor(success: boolean, settings: any, settingsVersion: number = -1, exception: any = null, message: string = null) {
    this.success = success;
    this.settings = settings;
    this.settingsVersion = settingsVersion;
    this.exception = exception;
    this.message = message;
  }
}

export interface IClientConfiguration {
  settings: Object;
  version: number;
}

                                                                                                                                                                                                                                                                     

export class DefaultErrorParser implements IErrorParser {
  public parse(context: EventPluginContext, exception: Error): IError {
    function getParameters(parameters: string | string[]): IParameter[] {
      let params: string[] = (typeof parameters === 'string' ? [parameters] : parameters) || [];

      let result: IParameter[] = [];
      for (let index = 0; index < params.length; index++) {
        result.push({ name: params[index] });
      }

      return result;
    }

    function getStackFrames(stackFrames: TraceKit.StackFrame[]): IStackFrame[] {
      const ANONYMOUS: string = '<anonymous>';
      let frames: IStackFrame[] = [];

      for (let index = 0; index < stackFrames.length; index++) {
        let frame = stackFrames[index];
        frames.push({
          name: (frame.func || ANONYMOUS).replace('?', ANONYMOUS),
          parameters: getParameters(frame.args),
          file_name: frame.url,
          line_number: frame.line || 0,
          column: frame.column || 0
        });
      }

      return frames;
    }

    const TRACEKIT_STACK_TRACE_KEY: string = '@@_TraceKit.StackTrace'; // optimization for minifier.

    let stackTrace: TraceKit.StackTrace = !!context.contextData[TRACEKIT_STACK_TRACE_KEY]
      ? context.contextData[TRACEKIT_STACK_TRACE_KEY]
      : TraceKit.computeStackTrace(exception, 25);

    if (!stackTrace) {
      throw new Error('Unable to parse the exceptions stack trace.');
    }

    return {
      type: stackTrace.name,
      message: stackTrace.message || exception.message,
      stack_trace: getStackFrames(stackTrace.stack || [])
    };
  }
}

                                                                                                                                                                                                         

export class DefaultModuleCollector implements IModuleCollector {
  public getModules(context: EventPluginContext): IModule[] {
    if (document && document.getElementsByTagName) {
      return null;
    }

    let modules: IModule[] = [];
    let scripts: NodeListOf<HTMLScriptElement> = document.getElementsByTagName('script');
    if (scripts && scripts.length > 0) {
      for (let index = 0; index < scripts.length; index++) {
        if (scripts[index].src) {
          modules.push({
            module_id: index,
            name: scripts[index].src,
            version: Utils.parseVersion(scripts[index].src)
          });
        } else if (!!scripts[index].innerHTML) {
          modules.push({
            module_id: index,
            name: 'Script Tag',
            version: Utils.getHashCode(scripts[index].innerHTML).toString()
          });
        }
      }
    }

    return modules;
  }
}

                                                                                                                                                                                                                             

export class DefaultRequestInfoCollector implements IRequestInfoCollector {
  public getRequestInfo(context: EventPluginContext): IRequestInfo {
    if (!document || !navigator || !location) {
      return null;
    }

    let exclusions = context.client.config.dataExclusions;
    let requestInfo: IRequestInfo = {
      user_agent: navigator.userAgent,
      is_secure: location.protocol === 'https:',
      host: location.hostname,
      port: location.port && location.port !== '' ? parseInt(location.port, 10) : 80,
      path: location.pathname,
      // client_ip_address: 'TODO',
      cookies: Utils.getCookies(document.cookie, exclusions),
      query_string: Utils.parseQueryString(location.search.substring(1), exclusions)
    };

    if (document.referrer && document.referrer !== '') {
      requestInfo.referrer = document.referrer;
    }

    return requestInfo;
  }
}

                                                                                                                                                                              

declare var XDomainRequest: { new (); create(); };

export class DefaultSubmissionAdapter implements ISubmissionAdapter {
  public sendRequest(request: SubmissionRequest, callback: SubmissionCallback, isAppExiting?: boolean) {
    // TODO: Handle sending events when app is exiting with send beacon.
    const TIMEOUT: string = 'timeout';  // optimization for minifier.
    const LOADED: string = 'loaded';  // optimization for minifier.
    const WITH_CREDENTIALS: string = 'withCredentials';  // optimization for minifier.

    let isCompleted: boolean = false;
    let useSetTimeout: boolean = false;
    function complete(mode: string, xhr: XMLHttpRequest) {
      function parseResponseHeaders(headerStr) {
        function trim(value) {
          return value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
        }

        let headers = {};
        let headerPairs = (headerStr || '').split('\u000d\u000a');
        for (let index: number = 0; index < headerPairs.length; index++) {
          let headerPair = headerPairs[index];
          // Can't use split() here because it does the wrong thing
          // if the header value has the string ": " in it.
          let separator = headerPair.indexOf('\u003a\u0020');
          if (separator > 0) {
            headers[trim(headerPair.substring(0, separator).toLowerCase())] = headerPair.substring(separator + 2);
          }
        }

        return headers;
      }

      if (isCompleted) {
        return;
      }

      isCompleted = true;

      let message: string = xhr.statusText;
      let responseText: string = xhr.responseText;
      let status: number = xhr.status;

      if (mode === TIMEOUT || status === 0) {
        message = 'Unable to connect to server.';
        status = 0;
      } else if (mode === LOADED && !status) {
        status = request.method === 'POST' ? 202 : 200;
      } else if (status < 200 || status > 299) {
        let responseBody: any = xhr.responseBody;
        if (!!responseBody && !!responseBody.message) {
          message = responseBody.message;
        } else if (!!responseText && responseText.indexOf('message') !== -1) {
          try {
            message = JSON.parse(responseText).message;
          } catch (e) {
            message = responseText;
          }
        }
      }

      callback(status || 500, message || '', responseText, parseResponseHeaders(xhr.getAllResponseHeaders && xhr.getAllResponseHeaders()));
    }

    function createRequest(userAgent: string, method: string, url: string): XMLHttpRequest {
      let xhr: any = new XMLHttpRequest();
      if (WITH_CREDENTIALS in xhr) {
        xhr.open(method, url, true);

        xhr.setRequestHeader('X-Exceptionless-Client', userAgent);
        if (method === 'POST') {
          xhr.setRequestHeader('Content-Type', 'application/json');
        }
      } else if (typeof XDomainRequest !== 'undefined') {
        useSetTimeout = true;
        xhr = new XDomainRequest();
        xhr.open(method, location.protocol === 'http:' ? url.replace('https:', 'http:') : url);
      } else {
        xhr = null;
      }

      if (xhr) {
        xhr.timeout = 10000;
      }

      return xhr;
    }

    let url = `${request.serverUrl}${request.path}?access_token=${encodeURIComponent(request.apiKey) }`;
    let xhr = createRequest(request.userAgent, request.method || 'POST', url);
    if (!xhr) {
      return callback(503, 'CORS not supported.');
    }

    if (WITH_CREDENTIALS in xhr) {
      xhr.onreadystatechange = () => {
        // xhr not ready.
        if (xhr.readyState !== 4) {
          return;
        }

        complete(LOADED, xhr);
      };
    }

    xhr.onprogress = () => { };
    xhr.ontimeout = () => complete(TIMEOUT, xhr);
    xhr.onerror = () => complete('error', xhr);
    xhr.onload = () => complete(LOADED, xhr);

    if (useSetTimeout) {
      setTimeout(() => xhr.send(request.data), 500);
    } else {
      xhr.send(request.data);
    }
  }
}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     

function getDefaultsSettingsFromScriptTag(): IConfigurationSettings {
  if (!document || !document.getElementsByTagName) {
    return null;
  }

  let scripts = document.getElementsByTagName('script');
  for (let index = 0; index < scripts.length; index++) {
    if (scripts[index].src && scripts[index].src.indexOf('/exceptionless') > -1) {
      return Utils.parseQueryString(scripts[index].src.split('?').pop());
    }
  }
  return null;
}

function processUnhandledException(stackTrace: TraceKit.StackTrace, options?: any): void {
  let builder = ExceptionlessClient.default.createUnhandledException(new Error(stackTrace.message || (options || {}).status || 'Script error'), 'onerror');
  builder.pluginContextData['@@_TraceKit.StackTrace'] = stackTrace;
  builder.submit();
}

/*
TODO: We currently are unable to parse string exceptions.
function processJQueryAjaxError(event, xhr, settings, error:string): void {
  let client = ExceptionlessClient.default;
  if (xhr.status === 404) {
    client.submitNotFound(settings.url);
  } else if (xhr.status !== 401) {
    client.createUnhandledException(error, 'JQuery.ajaxError')
      .setSource(settings.url)
      .setProperty('status', xhr.status)
      .setProperty('request', settings.data)
      .setProperty('response', xhr.responseText && xhr.responseText.slice && xhr.responseText.slice(0, 1024))
      .submit();
  }
}
*/

let defaults = Configuration.defaults;
let settings = getDefaultsSettingsFromScriptTag();
if (settings && (settings.apiKey || settings.serverUrl)) {
  defaults.apiKey = settings.apiKey;
  defaults.serverUrl = settings.serverUrl;
}

defaults.errorParser = new DefaultErrorParser();
defaults.moduleCollector = new DefaultModuleCollector();
defaults.requestInfoCollector = new DefaultRequestInfoCollector();
defaults.submissionAdapter = new DefaultSubmissionAdapter();

TraceKit.report.subscribe(processUnhandledException);
TraceKit.extendToAsynchronousCallbacks();

// window && window.addEventListener && window.addEventListener('beforeunload', function () {
//   ExceptionlessClient.default.config.queue.process(true);
// });

// if (typeof $ !== 'undefined' && $(document)) {
//   $(document).ajaxError(processJQueryAjaxError);
// }

(<any>Error).stackTraceLimit = Infinity;

declare var $;

